import React, {Component}  from 'react';
import './App.css';
import lkys from './img/lkys.jpg';
import jjcc from './img/jjcc.jpg';
import jytj from './img/jytj.jpg'
import lk from './img/lk30year.jpg'
import lkpc_png from './img/lkpc.png'
import jytj_png from './img/jytj.png'
import lkkt_png from './img/lkkt.png'
import lkzx_png from './img/lkzx.png'
var domain="http://doctor-wxmp.lkimt.com/"

export default class Users extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
            , product: {
                url: ""
            }
        }
    }

    componentDidMount() {
    }
    componentWillMount(){


    }
    componentWillReceiveProps(nextProps) {
        // console.log("i receive", nextProps)
        if (nextProps.product) {
            let {price}=nextProps.product.commodity
            price =this.toMoney(price)
            nextProps.product.commodity.price=price
            this.setState({
                product: nextProps.product
            })
        }
    }

    render() {
        return (
            <div className="container" >
                <div className={"lk-30"}>
                    <img src={lk} alt={""}/>
                </div>
             <div className={"radius-container"}>
                 <a href={domain+"s/faq"}>
                 <img src={jytj}  alt={""}/>
                 </a>
            </div>
                <div className={"radius-container"}>
                    <a href={"https://doctor-wxmp-jjcc.lkimt.com/"}>
                    <img src={jjcc}  alt={""}/>
                    </a>
                </div>

                <div className={"radius-container"}>
                    <img src={lkys}  alt={""}/>
                </div>

                <div className={"btns"}>
                    <a href={domain+"s/self-assessment"}>
                        <img src={lkpc_png} alt={""}/>
                    </a>
                    <a href={domain+"s/faq"}>
                        <img src={jytj_png} alt={""}/>
                    </a>
                    <a href={domain+"s/articles"}>
                        <img src={lkkt_png} alt={""}/>
                    </a>
                    {/*<a href={"https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU3Njc5MjkwNQ==&scene=126&bizpsid=0&sharer_username=gh_485506912495&subscene=0&clicktime=1558852845#wechat_redirect"}>*/}
                    <img src={lkzx_png} alt={""}/>
                    {/*</a>*/}
                </div>
            </div>

        )

    }
}
// export default App;

